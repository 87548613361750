@mixin tablet{
    @media screen and (max-width: 820px){
        @content;        
    }
}

@mixin mobile {
    @media screen and (max-width: 425px){
        @content;        
    }
}