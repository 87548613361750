$azulPrincipal: #193773;
$azulClaro: #D0D9F2;
$grisPrincipal: #403E3F;
$amarilloPrincipal: #F2CB05;
$blancoPrincipal: #F2F2F2;
$cEbanisteria: #ffcc29;
$cEstudiantil: #ed3237;
$cGas: #f2894f;
$cGriferia: #0097e0;
$cElectricos: #02a55a;
$cTornilleria: #83706b;
//colores de categorias
.mainBlue{
    color: $azulPrincipal;
}
.Cebanisteria{
    color: $cEbanisteria;
}

.Cestudiantil{
    color: $cEstudiantil;
}

.Cgas{
    color: $cGas;
}

.Cgriferia{
    color: $cGriferia;
}

.Celectricos{
    color: $cElectricos;
}

.Ctornilleria{
    color: $cTornilleria;
}
.cBlack{
    color: black;
}