@import "../../scss/_colors.scss";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
  border-style: none;
}

.modal-content.productBox{
    padding: 40px !important;
}

.imgModal, .theLogo{
    position: relative;
    display: flex;
    padding: 10px;
    justify-content: center;
    overflow: hidden;
    picture{
        width: 90%;
    }
    img{
        border: solid;
        border-radius: 25%;
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: contain;
    }
}
.theLogo{
    justify-content: left;
    align-items: center;
    padding: 0;    
    picture{
        //width: 20%;
        width: 3.625rem;
    }
    img{
        border: none;
        border-radius: 0;
    }
}

.soldOutMod{
    position: absolute;
    padding: 0px 111px;
    color: #FF4A4A;
    font-weight: 700;
    background-color: rgba(255, 0, 0, 0.34);
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 20%;
    left: 20%;
}

.commingsoon{
    opacity: 0;
}

.description{
    height: 120px;
    background-color: #d9d9d9;
}

.mainFeatures{
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.quantityText{
    color: $azulPrincipal;
    font-weight: bold;
}

.quantity{
    font-size: 1.5rem;
    text-align: center;
    border-style: none;    
}

.unitPrice{
    padding-top: 8px;
}
.totalPrice{
    flex-wrap: wrap;
    padding: 8px 0;
}

//Buttons
.xButton{
    position: absolute;
    font-size: 35px;
    z-index: 2;
    right: -4px;
    top: -7px;
    border-style: none;
    color: $grisPrincipal;
    background-color: transparent;
    transition: 300ms;
}
.btnAddCart, .btnQuantity, .modalBtnLogin{
    background-color: $amarilloPrincipal;
    color: $azulPrincipal;
    border-radius: 10px;
    border-style: none;
    font-weight: bold;
    height: 50px;
}
.btnQuantity{
    background-color: $azulPrincipal;
    color: white;
    height: auto;
}
.btnAddCart, .modalBtnLogin{
    border-radius: 12px;
    width: 100%;
}
.modalBtnLogin.boton{
    max-width: none;
    background-color: #0cc042;
    letter-spacing: normal;
}
.btnAddCart.boton{
    max-width: none;
    color: $azulPrincipal;
    letter-spacing: normal;
}
.btnQuantity:active{
    background-color: $amarilloPrincipal;
    color: $azulPrincipal;
}

.btnAddCart:disabled{
    opacity: 0.5;
}

@media (max-width: 700px){
    #productoLabel {
        margin-left: 20px;
    }
    h1{
        font-size: 1rem !important;
    }
    .xButton{
        font-size: 25px;
    }
    .mainFeatures{
        display: flex;
        max-height: 167px;
        border-bottom: none;
    }
    .modal-content.productBox{
        padding: 25px !important;
    }
    .totalPrice{
        padding: 0;
    }
}