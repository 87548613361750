@import "../../scss/colors";
@import "../../scss/mediaQueries";

*{
    box-sizing: border-box;
}

.cabecera{    
    position: relative;    
    padding: 1.25rem 0px 0px;
    background-color: $azulPrincipal;
}
.backImg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
}

.userNameHead{
    color: white;
    font-size: 14px;
    margin: 0px 12px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.userIcon{
    width: 36px;
    height: 36px;
    color: white;
    position: relative;
    display: flex;
    text-decoration: none;
    .userHex{
        font-size: 36px;
        line-height: 0;
    }
    .userText{
        position: absolute;
        line-height: 1;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        color: black;
        font-size: 20px;
    }
}

.userHex:hover, .userIcon:hover{
    color: $amarilloPrincipal;
}

.grupoBotones{
    display: flex;
    justify-content: center;
    .g1{
        width: 72%;
    }
}

.g2{
    display: none;   
}

.col.logo{
    max-width: calc((100 / 3)*1%);
}
.logo{
    z-index: 1;
    a{
        width: calc((3*18.59)*1%);
        img{
            width: 100%;
            height: auto;
        }
    }
    
}

.buscar.mob{
    display: none;
}
.col.buscar{
    max-width: calc((100 / 3)*1%);
    padding: 0;
}
.buscar{    
    input{
        width: 100%;
        border-radius: 10px 0px 0px 10px;
        border: solid 1px;
        padding: 10px;
    }
    i{
        display: flex;
        position: absolute;
        top: 0px;
        right: -8px;
        background-color: #e9eced;
        border-radius: 0px 10px 10px 0px;
        border: solid 1px;
        padding: 0px 10px;
        height: 100%;
        align-items: center;
    }
}

.col.user{
    display: flex;
    max-width: calc((100 / 3)*1%);
    justify-content: flex-end;
    align-items: center;
    .btnCart, .btnSignIn{
        position: relative;
        display: flex;
        color: black;
        font-weight: bold;
        text-decoration: none;
        padding: 6px;
        background-color: white;
        border-radius: 14px;
        border: 1px $azulClaro solid;
        margin-right: 5px;
    }
    .btnCart{
        border-radius: 17px;
        i{
            margin: 0px 3px;
        }
    }
}
.btnSignIn{
    margin-right: 0px;
    i{
        margin-right: 4px;
    }
}
.col.user .btnSignIn:hover{
    background-color: $amarilloPrincipal;
}
.col.user .btnCart:hover{
    background-color: $amarilloPrincipal;
}

.btn.btn-navBar{
    height: 50px;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    background-color: $blancoPrincipal;
    border-style: solid;
    border-color: $azulClaro;
    border-width: 1px;
    --bs-btn-border-radius: 0.9rem;
    --bs-btn-active-bg: #F2CB05;//Cambiar después a uno más oscuro c: definir color en colors.scss
}

.floatingNumber {
    position: absolute;
    width: max-content;
    aspect-ratio: 1 / 1;
    background-color: $amarilloPrincipal;
    line-height: 1;
    padding: 2px;
    border-radius: 25px;
    top: -22%;
    right: -10%;
}

.btn.btn-navBar:hover{
    background-color: $amarilloPrincipal;
}

.sticky{
    position: fixed;
    top: 0;
    z-index: 1;
}

#headLogoComp{
    display: initial;
}
#headLogoMobile{
    display: none;
}

@include tablet{
    .cabecera{
        padding: 0.4rem 0px 0px;
        .row.position-relative{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .grupoBotones .g1{//Esconde Botones de nav
        display: none;
    }

    .col.g2{
        max-width: 40%;
    }
    .g2{//muestra el menu de nav para celular o tablet
        display: flex;
        align-items: center;
        padding: 0;        
        .menuNav{
            .bi{
                font-size: 7.8vw;
            }
            height: max-content;
            color: $blancoPrincipal;
            background-color: transparent;
            border-style: none;
        }
    }

    .col.logo{//redimensiona el logo sierra
        display: none;
    }
    .logo.mob{
        width: 68%;
        a{
            width: unset;
            img{
                //height: 5rem;
                width: 100%;
            }
        }
    }
    
    .col.buscar{//oculta la barra de buscar
        display: none;
    }
    .buscar.mob{
        display: flex;
        width: 100%;
    }

    .col.user{
        padding: 5px;
        max-width: unset;
        a{
            width: min-content;
            height: 30px;
        }
        .btnCart{
            i{
                margin: 0px 0px;
            }
        }
    }
    .btnSignIn, .btnCart{
        i{
            margin-right: 0;
            transform: translate(0px, -3px);
        }
        .SignInSpan{
            display: none;
        }
    }
}