@import "./scss/index";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: "Norwester";
  src: url("./Assets/fonts/norwester.otf") format("truetype");
}

@font-face {
  font-family: "Kollektif";
  src: url("./Assets/fonts/Kollektif.ttf") format("truetype");
}
@font-face {
  font-family: "KollektifItalic";
  src: url("./Assets/fonts/Kollektif-Italic.ttf") format("truetype");
}

*{
  box-sizing: border-box;
}

html, iframe { margin:0; padding:0; height:100%; }

body {
  margin: 0;
  padding:0;
  height:100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: "Norwester", sans-serif;
  font-size: 2rem;
}

.anima-Entrada{
  opacity: 1;
  transform: translateY(0px);
  transition: 1s;
}

//Fonts
.font-19{
  font-size: 1.1875rem;
}

.Tit{
  font-family: "Norwester", sans-serif;
  font-size: 2.025rem;//32.4px
}
.subTit{
  font-family: "KollektifItalic", sans-serif;
  font-size: 1.5rem;//24px i guess xD
}
.genFont{
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;//20px
}
.smolText{
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;//16px i guess x2
}

//Buttons
.btnStlGen{  
  background-color: $azulPrincipal;
  color: $amarilloPrincipal;
  font-size: 24px;
  font-weight: bold;
  border-radius: 30px;
}