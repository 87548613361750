//@import "../../scss/index.scss";
.products{
    min-height: 450px;
    padding: 0.9044rem 7rem;
}

.pptn{
    margin-left: 3%;
    font-weight: 600;
    font-size: 32px;
}

.productsContainer{
    flex-wrap: wrap;
    display: flex;    
    .caja{
        margin: 0% 3% 3% 3%;
    }
}

.nFound{//banner products not found
    display: flex;
    background-color: #d9d9d9;
    color: #727272;
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    padding: 20px 0px;
    justify-content: center;
}

.pcFoot{
    background-color: black;
    width: 100%;
    height: 1px;
}

.loadingStls{
    animation: skeleton-loading 1s linear infinite alternate;
}

.dots {
    overflow: hidden;
    animation: dotsAnimation 1.5s linear infinite alternate;
}

.modal-open {
    overflow: hidden;
}

@keyframes dotsAnimation {
    0%{
        width: 0%;
    }
    100%{
        width: 10%;
    }
}  

@keyframes skeleton-loading {
    0% {
        background-color: rgb(217, 217, 217)
    }

    100%{
        background-color: hsl(0, 0%, 100%);
    }
}

@media (max-width: 900px){
    .products{
        padding: 0.9044rem 4rem;
    }

    .pptn{
        margin-left: 6%;
    }

    .productsContainer{        
        .caja{
            margin: 0% 6% 6% 6%;
            width: 20%;
        }
    }
}

@media (max-width: 600px){
    .products{
        padding: 0.9044rem 1rem;
    }    
    .pptn{
        margin-left: 3%;
    }
    .productsContainer{        
        .caja{
            margin: 0% 2% 6% 3%;
            width: 44%;
            padding: 5%;
        }
    }
}

.theModalContainer {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #161B2266;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
}

.theModal-content {
    background-color: #f2f2f2;
    border-radius: 12px;
    margin: auto;
}
.theModal-body{
    padding: 10px;
}
.theModal-header {
    display: flex;
    justify-content: space-between;
}

.theTable.gmt{
    tbody{
        tr{
            background-color: #f2f2f2;
        }
    }
}