@import "../../scss/_colors.scss";
@import "../../scss/mediaQueries";

.cCarrusel{
    width: 100%;
    height: auto;//alto fijo jaska
    position: relative;
    overflow: hidden;
}

#pContainer{//contiene cada item
    position: relative;
    left: 0%;
    align-items: center;
}

#leftButton, #rightButton{
    position: absolute;
    z-index: 2;
    left: 0;
    top: 41%;
    border-style: none;
    color: transparent;
    background-color: transparent;
    transition: 300ms;
}

#rightButton{
    right: 0;
    left: unset;
}

.cCarrusel:hover{
    #leftButton{
        color: $grisPrincipal;
    }
    
    #rightButton{
        color: $grisPrincipal;
    }
}

.cCarrusel #rightButton:hover{
    color: $amarilloPrincipal;
}

.cCarrusel #leftButton:hover{
    color: $amarilloPrincipal;
}

@media (max-width: 636px){
    #pContainer{
        .caja{
            margin: 0 28%;
            min-width: 44%;
            padding: 4%;
        }
    }    
}