@import "../../scss/_colors.scss";

.pol-datos{
    display: flex;
    align-items: center;
    min-height: 30rem;
    justify-content: center;
    padding: 10px;
    h1{
        color: $azulPrincipal;
        position: relative;
        text-align: center;
        margin-top: 1.1875rem;
    }
    ul{
        list-style: none;
        padding: 0;
        width: min-content;
    }
}