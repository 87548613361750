@import '../../scss/mediaQueries';

.grayContainer.profile{
    align-items: baseline;
    width: 80%;
}

.tuercaContainer.profile{
    top: 15%;
    left: 15%;
}

.hexagon.profile{
    font-size: 100vw;
}
.hexagon.profile::before {
    font-size: 34.772727272%
}

.userLogo.profile{
    //font-size: 229.5px;
    font-size: 100vw;
    border-radius: 200px;
}
.userLogo.profile::before{
    font-size: 17.386363636363%;
}

.userData{
    width: 58%;
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    margin-left: 42%;
}

.userData > *{
    margin-bottom: 20px;
    overflow-x: auto;
}

.btn-container1{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
}
.btnStlGen.profile{
    font-weight: normal;
    width: 215px;
    margin-bottom: 20px;
}

// here is additional styles

.goLogin{
    width: 100%;
    padding: 0px 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;    
}
.hexContainer{
    display: flex;
    width: 100%;
    margin: 18px 0px;
    position: relative;    
    justify-content: center;
    height: min-content;
}
.goLoginBtn.boton{
    max-width: none;
    width: 215px;
    background-color: #0cc042;
    letter-spacing: normal;
}

.hexContainer .hexagon{
    animation: spin 5s linear infinite;
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
//end others styles

@media screen  and (max-width: 852px){
    .tuercaContainer.profile{
        top: 7%;
    }    
}

@media screen  and (max-width: 820px){
    .btn-container1{
        position: unset;
    }
    .tuercaContainer.profile{
        top: 5%;
    }
}

@media screen  and (max-width: 610px){
    .userData{
        margin-left: 0;
        width: 100%;
        width: -webkit-fill-available;
        width: -moz-available;  
    }
    .tuercaContainer.profile{
        top: 0%;
        left: 50%;
    }
    .btn-container1{
        align-self: center;
    }
}