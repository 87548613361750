@import '../../scss/mediaQueries';
@import "../../scss/_colors.scss";

.catalogo{
    position: relative;
    min-height: 26rem;
    padding: 0px 12px;
}

.pdfViewer {
    display: flex;
    justify-content: center;
    position: relative;
}

.catalogoMenu{    
    position: absolute;
    top: 10px;
}

.btnMenuCatalogo{
    background-color: currentColor;
    margin: 0px 4px;
    padding: 4px 12px;
    border: 2px black solid;
    text-decoration: none;
    color: black;
    font-weight: bold;
    border-radius: 20px;    
}

.catalogoMenuContainer{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 35px;
    height: 72px;
    justify-content: center;
}