@import "../../scss/_colors.scss";
@import '../../scss/mediaQueries';

.grayContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 517px;
    margin-top: 5%;
    padding: 123px 41px 73px;
    background-color: #d9d9d9;
    border-radius: 66px;
    .logText{
        color: #8b8b8b;
        font-weight: bold;
    }
}

.tuercaContainer{
    display: flex;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    width: 100%;
    height: min-content;
    .hexagon{
        transform: rotate(90deg);
    }
}
.hexagon::before{
    display: flex;
    color: $azulPrincipal;
    font-size: 163px;
}
.userLogo{
    display: flex;
    background: white;
    border: solid #F2CB05;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    align-items: center;
    justify-content: center;
    font-size: 80px;//50% de size hexagon
}

.userEmail, .userPass{
    position: relative;
    width: 397px;
    margin: 15px 0px;
    border-bottom: solid 1px #8b8b8b;
    .theInput{
        border-style: none;
        background-color: transparent;
        margin-left: 50px;
        font-size: 24px;
    }
    i{
        position: absolute;
        top: -10px;
        font-size: 36px;
    }
}
.theInput:focus-visible{
    outline: none;
    font-weight: normal;
}

.btnLogin{
    display: block;
    width: 90%;
    height: 70px;    
    margin: 25px auto 0;
}
.btnRegistro{
    background-color: $amarilloPrincipal !important;
    color: $azulPrincipal !important;
    width: 62%;
}
.btnLogin:active{
    background-color: $amarilloPrincipal;
    color: white;
}

input.theCheck{
    width: 14px !important;
    height: 14px !important;
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    box-shadow: none;
    font-size: 0.8em;
    text-align: center;
    line-height: 1em;
    background: $azulPrincipal;
}  
input.theCheck:checked:after {    
    position: absolute;
    left: 15%;
    content: "✔";
    color: white;
}

@include tablet{
    .grayContainer{
        margin-top: 8%;
    }    
    .tuercaContainer{
        img{
            width: 35%;
            height: auto;
        }
    }
}

@media screen and (max-width: 570px){
    .grayContainer{
        margin-top: 8%;
        width: 80%;
    }

    .userEmail, .userPass{
        width: 100%;
        .theInput{
            width: 70%;
            font-size: 100%;
        }
        i{
            top: -85%;
        }
    }
    .toDirCol{
        display: flex;
        flex-direction: column;
    }
}