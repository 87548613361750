@import "../../scss/_colors.scss";

.thePdfViewer{    
    //width: 64.375rem;    
    padding: 4rem 0rem;
    margin: 0rem 1.25rem;
    display: flex;
    scrollbar-width: none;
    overflow: auto visible;
}
.thePdfViewer::-webkit-scrollbar {
    display: none;
}

.pagesContainer{
    display: flex;
    height: auto;    
}
.dirRow{
    flex-direction: row;
}
.dirColumn{
    flex-direction: column;
}

.page{
    border-width: 3px;
    border-style: groove;
}

.next, .prev {
    font-size: 1.875rem;
    position: absolute;
    top: 50%;
    border-style: none;
    color: transparent;
    background-color: transparent;
    transition: 300ms;
}

.next{
    left: 88%;
}

.pdfViewer:hover{
    .next, .prev {
        color: $grisPrincipal;
    }
}

.pdfViewer .next:hover {
    color: $amarilloPrincipal;
}

.pdfViewer .prev:hover {
    color: $amarilloPrincipal;
}

.no{
    display: none;
    visibility: hidden;
}

@media screen and (max-width: 636px) {
    .thePdfViewer{
        padding: 5rem 0rem;
    }
}
@media screen and (max-width: 375px) {
    .thePdfViewer{
        padding: 7.5rem 0rem;
    }
}